<template>
    <div class="w-100 px-2 mt-5 pb-5" style="max-width: 420px; margin: auto; overflow: hidden">
        <div class="text-center mb-3"><img src="img/layout/user-image.svg" width="64px"/></div>
        <div class="card card-default card-login" :class="loading ? 'whirl' : ''">
            <div class="card-header text-center py-3 border-0">
                {{$t('common.view.public.login.heading')}}
            </div>
            <div class="card-body pt-0 px-4 pb-4 rounded-0" style="background-image: none;">
                <form @submit.prevent="doLogin" data-vv-scope="login">
                    <div class="form-group">
                        <div class="input-group with-focus">
                            <input :class="{'form-control':true, 'is-invalid': errors.has('login.email')}" class="border-right-0" :placeholder="$t('common.view.public.login.form.email.placeholder')" v-model="login.email" v-validate="'required|email'" type="text" name="email" autofocus="autofocus"/>
                            <div class="input-group-append">
                                <span class="input-group-text text-white bg-transparent pl-0">
                                    <em class="fad fa-envelope  text-secondary"></em>
                                </span>
                            </div>
                            <span v-show="errors.has('login.email')" class="invalid-feedback">{{ errors.first('login.email') }}</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <div class="input-group with-focus">
                            <input :class="{'form-control':true, 'is-invalid': errors.has('login.password')}" class="border-right-0" v-model="login.password" v-validate="'required'" type="password" name="password" :placeholder="$t('common.view.public.login.form.password.placeholder')"/>
                            <div class="input-group-append">
                                <span class="input-group-text text-white bg-transparent pl-0">
                                    <em class="fad fa-key text-secondary"></em>
                                </span>
                            </div>
                            <span v-show="errors.has('login.password')" class="invalid-feedback">{{ errors.first('login.password') }}</span>
                        </div>
                    </div>

                    <div v-if="!$isApp" class="form-group mb-0 mt-2">
                        <label class="switch switch-sm d-inline mr-2">
                            <input type="checkbox" checked="checked" v-model="login.rememberme"/>
                            <span></span>
                        </label>

                        <small>{{$t('common.view.public.login.stay_logged_in')}}</small>
                    </div>

                    <div class="float-right mb-0" v-if="!$isApp">
                        <router-link class="text-secondary" tabindex="-1" to="/recover">
                            <small>{{$t('common.view.public.login.lost_access')}}</small>
                        </router-link>
                    </div>
                    <button class="btn btn-block btn-primary mb-3" type="submit" :disabled="loading">Login <i class="fad fa-sign-in ml-1"></i></button>
                </form>
                <template v-if="!$isApp">
                    <p class="text-right mb-0">
                        <small>{{$t('common.view.public.login.signup.label')}}</small>
                    </p>
                    <router-link class="btn btn-block btn-secondary" to="/register">{{$t('common.view.public.login.signup.link')}}</router-link>
                </template>
            </div>

            <div class="bg-white p-3 border-top">
                <img class="mt-1" src="img/logo/cannergrow-full.svg"/>
            </div>
        </div>
    </div>
</template>
<style lang="scss">

    .target-selector {
        .btn {
            width: 100%;
            height: 40px;

            &.active {
                background-color: #bfc51d !important;
                color: white !important;
            }
        }
    }

    .welcome-text {
        position: absolute;
        left: 100%;
        color: gray;
        width: 237px;
        margin-left: 30px;
    }

    @media screen and (max-width: 900px) {
        .welcome-text {
            display: none;
        }
    }
</style>
<script>

    export default {
        data() {
            return {
                login: {
                    email: '',
                    password: '',
                    rememberme: this.$isApp,
                    verification_code: '',
                    language: this.$i18n.locale
                },
                loading: false,
                target: 'backend'
            }
        },
        mounted() {
            if (this.$route.query.target) {
                 this.target = this.$route.query.target;
             }
        },
        methods: {
            changeTarget(target) {
                if (this.target !== target) {
                    this.target = target;
                    this.$router.replace({name: 'Login', query: {target: target}});
                }
            },
            doLogin: function (scope) {
                this.loading = true;
                this.$validator.validateAll(scope).then((result) => {
                    if (result) {
                        //   this.login.error = false;
                        this.$api.post('/auth/login', this.login).then(response => {
                            // login user, store the token and redirect to dashboard
                            this.$auth.login(response.data);
                            this.$fcm.registerDevice();
                        }).catch(error => {
                            this.loading = false;
                            this.login.verification_code = '';
                            if (error.status === 401) {
                                if (error.data.action === 'request_2fa') {
                                    this.$swal.fire({
                                        title: 'Two Factor Authentication',
                                        input: 'text',
                                        inputPlaceholder: 'Enter your one time password here'
                                    }).then(response => {
                                        this.login.verification_code = response.value;
                                        this.doLogin(scope);
                                    });
                                } else {
                                    this.$swal.fire('wrong credentials', '', 'error');
                                }
                            }
                            if (error.status === 422) {
                                this.$swal.fire('Error', error.data.message, 'error');
                            }
                        });
                    } else {
                        this.loading = false;
                    }
                });
            }
        }
    }
</script>
